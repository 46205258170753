.special-offers .embla {
    /* max-width: 48rem; */
    max-width: 100%;
    margin: auto;
    --slide-height: 19rem;
    --slide-spacing: 1rem;
    --slide-size: 100%;
}

.special-offers .embla__viewport {
    overflow: hidden;
}

.special-offers .embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    margin-left: calc(var(--slide-spacing) * -1);
}

.special-offers .embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
}

.special-offers .embla__slide:nth-child(1) {
    flex: 0 0 20%;
}

.special-offers .embla__slide:nth-child(2) {
    flex: 0 0 35%;
}

.special-offers .embla__slide:nth-child(3) {
    flex: 0 0 20%;
}

.special-offers .embla__slide:nth-child(4) {
    flex: 0 0 25%;
}

.special-offers .embla__slide:nth-child(5) {
    flex: 0 0 35%;
}

.special-offers .embla__slide:nth-child(6) {
    flex: 0 0 25%;
}

.special-offers .embla__slide:nth-child(7) {
    flex: 0 0 30%;
}

.special-offers .embla__slide:nth-child(8) {
    flex: 0 0 20%;
}

.special-offers .embla__slide:nth-child(9) {
    flex: 0 0 25%;
}

.special-offers .embla__slide__number {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    border-radius: 1.8rem;
    font-size: 4rem;
    font-weight: 600;
    display: flex;
    align-items: center;
    justify-content: center;
    height: var(--slide-height);
}

.special-offers .embla__controls {
    display: grid;
    grid-template-columns: auto 1fr;
    justify-content: space-between;
    gap: 1.2rem;
    margin-top: 1.8rem;
}

.special-offers .embla__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
    align-items: center;
}

.special-offers .embla__button {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 3.6rem;
    height: 3.6rem;
    z-index: 1;
    border-radius: 50%;
    color: var(--text-body);
    display: flex;
    align-items: center;
    justify-content: center;
}

.special-offers .embla__button:disabled {
    color: var(--detail-high-contrast);
}

.special-offers .embla__button__svg {
    width: 35%;
    height: 35%;
}

.special-offers .embla__selected-snap-display {
    justify-self: flex-end;
    align-self: center;
    color: var(--text-low-contrast);
    font-weight: 600;
}


.special-offers .embla__slide-img {
    /* border-radius: 10px; */
    display: block;
    height: var(--slide-height);
    width: 100%;
    object-fit: cover;
}


@media screen and (max-width:992px) {
    .special-offers .embla__slide:nth-child(1) {
        flex: 0 0 30%;
    }

    .special-offers .embla__slide:nth-child(2) {
        flex: 0 0 45%;
    }

    .special-offers .embla__slide:nth-child(3) {
        flex: 0 0 30%;
    }

    .special-offers .embla__slide:nth-child(4) {
        flex: 0 0 35%;
    }

    .special-offers .embla__slide:nth-child(5) {
        flex: 0 0 45%;
    }

    .special-offers .embla__slide:nth-child(6) {
        flex: 0 0 35%;
    }

    .special-offers .embla__slide:nth-child(7) {
        flex: 0 0 40%;
    }
}

@media screen and (max-width:644px) {
    .special-offers .embla__slide:nth-child(1) {
        flex: 0 0 55% !important;
    }

    .special-offers .embla__slide:nth-child(2) {
        flex: 0 0 70% !important;
    }

    .special-offers .embla__slide:nth-child(3) {
        flex: 0 0 55% !important;
    }

    .special-offers .embla__slide:nth-child(4) {
        flex: 0 0 60% !important;
    }

    .special-offers .embla__slide:nth-child(5) {
        flex: 0 0 65% !important;
    }

    .special-offers .embla__slide:nth-child(6) {
        flex: 0 0 60% !important;
    }

    .special-offers .embla__slide:nth-child(7) {
        flex: 0 0 65% !important;
    }
}