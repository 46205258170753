/* footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 999;
    width: 100%;
    background-color: var(--clr-light-2);
    height: 14rem !important;
    box-shadow: var(--shadow-2);
}

.footer-container {
    height: 16rem !important;
}

.condition {
    letter-spacing: 1px;
    text-transform: uppercase;
}

@media screen and (max-width:875px) {
    .condition {
        width: 50rem !important;
    }
}

@media screen and (max-width:810px) {
    .condition {
        width: 47rem !important;
    }
}

@media screen and (max-width:810px) {
    .condition {
        display: grid !important;
        justify-content: space-around !important;
        align-items: center !important;
    }
}

.footer-social-icon {
    font-size: 25px !important;
}

.condition {
    width: 55rem;
    display: flex;
    justify-content: space-around;
    align-items: center;
}

.condition-utisl::after {
    content: "" !important;
    position: absolute !important;
    height: 3rem !important;
    width: 1px !important;
    color: var(--clr-dark) !important;
    background-color: var(--clr-dark) !important;
    margin: -13px 0 0 44px !important;
}


.copyright {
    opacity: .7;
    font-size: 13px;
} */

.bg-footer {
    /* background-color: #fff5fd5c !important; */
    background-color: var(--clr-light-blue-2);
    /* background-color: rgb(255, 234, 248) !important; */
    box-shadow: var(--shadow-pink);
}

.copy-right-bg {
    /* background-color: rgb(255, 213, 240) !important; */
    background-color: var(--clr-blue)!important;
    /* background-color: var(--clr-pink) !important; */
}

.bg-footer .input-group-append {
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-60%, 30%);
    margin-top: 0rem;
}