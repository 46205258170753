.product-details {
    position: relative !important;
    /* max-width: 80px; */
}

.product-details-content {
    margin: 0 0 1rem 0 !important;
}

@media screen and (max-width:992px) {
    .product-details-content {
        margin: 0 0 0rem 0 !important;
    }

}

.product-details-title {
    font-family: var(--font-family-outfit-medium) !important;
    margin: 15px 0 10px 0 !important;
}

.product-details-prix {
    color: var(--clr-pink);
    font-family: var(--font-family-outfit-medium) !important;
    font-size: 23px;
}

.product-details-desc-parent {
    overflow: scroll;
    max-height: 35rem;
}

.product-details-desc {
    font-family: var(--font-family-outfit-medium) !important;
    color: var(--gray-2) !important;
    font-size: 20px;
    overflow: hidden;
}

/* 
.product-details-desc img {
    display: none;
} */

.product-details-total {
    color: var(--clr-purple) !important;
}

.product-details-total-text,
.product-details-total-price {
    font-size: 21px !important;
}

.product-details .container {
    cursor: default !important;
}

.product-details-image {
    max-width: 60% !important;
    max-height: 25rem !important;
}

.variant-row-details-m1 {
    margin: 33px 0px 0 0 !important;
}

.variant-row-details-m2 {
    margin: 30px 0 0!important;
}

/* .product-video iframe{
    border-radius: var(--radius-2) !important;
    box-shadow: var(--shadow-1) !important;
    transform: scale(80%) !important;
} */

.product-video {
    position: relative !important;
    padding-bottom: 56% !important;
    height: 0 !important;
    overflow: hidden !important;
    border-radius: var(--radius-2) !important;
    box-shadow: var(--shadow-1) !important;
    transform: scale(100%) !important;
}

.video-container {
    position: absolute !important;
    top: 0 !important;
    left: 0 !important;
    width: 100% !important;
    height: 100% !important;
}

.video-container iframe {
    width: 100% !important;
    height: 100% !important;
    border-radius: var(--radius-2) !important;
    box-shadow: var(--shadow-1) !important;
}


@media screen and (max-width:768px) {
    .product-video {
        transform: scale(105%) !important;
    }
}

@media screen and (max-width:576px) {
    .product-video {
        transform: scale(95%) !important;
    }
}

.share-into {
    color: var(--clr-purple);
}


.share-btn {
    color: var(--clr-purple);
    margin: 0px 0 7px 0;
    font-size: 27px;
}

.share-btn-text {
    color: var(--clr-purple);
}

.product-description {
    /* display: none; */
    overflow: hidden;
}

.product-description img {
    transform: scale(50%) !important;
    overflow: hidden;
    /* justify-content: start; */
    /* display: fle; */
    /* display: none; */
    /* position: absolute; */
}


/*
.bigImage img {
    width: 100%;
    border-radius: 5px;
}

.smallImages {
    display: flex;
    gap: 20px;
    margin-top: 20px;
}

.smallImages img {
    width: 20%;
    border-radius: 5px;
    cursor: pointer;
}  */

.desc-col {
    margin: 0 0 15rem 0 !important;
}

.desc-section .row {
    margin: 0 0 1rem 0 !important;
}

.desc-section-title {
    outline: none !important;
    border: none !important;
    opacity: .9 !important;
    font-size: 13px !important;
}

.desc-section-title::after {
    content: "";
    position: relative;
    display: block;
    background-color: var(--clr-purple) !important;
    width: 0%;
    height: 2px;
    transition: var(--transition);
}

.desc-section-title:hover::after {
    width: 100%;
}

.tab-content {
    font-family: var(--font-family-outfit-medium) !important;
    color: var(--gray-2) !important;
    font-size: 19px;
}

.btn.active::after {
    content: "";
    position: relative;
    display: block;
    background-color: var(--clr-purple) !important;
    width: 100%;
    height: 2px;
}

.review-form {
    font-family: var(--font-family-varela-round) !important;
}

.review-avatar {
    width: 80px;
}

.star-svg {
    width: 30px;
}

.review-box-btn {
    margin: 0 0 8rem 0 !important;
}

.error-message {
    width: 100%;
    margin-top: 0.25rem;
    font-size: .875em;
    color: #dc3545;
}