.swiper-pagination-fraction,
.swiper-pagination-custom,
.swiper-horizontal>.swiper-pagination-bullets,
.swiper-pagination-bullets.swiper-pagination-horizontal {
    z-index: 9999 !important;
    position: sticky !important;
}

.swiper-pagination {
    margin: 1rem 0 0px 0 !important;
    height: 2rem !important;
}


.swiper-pagination-bullet {
    width: 11px !important;
    height: 11px !important;
    background: var(--clr-purple) !important;
}

.swiper-pagination-bullet-active {
    background: var(--clr-purple) !important;
}

.swiper-pagination-bullet::after {
    content: "";
    display: block;
    width: 17px;
    height: 17px;
    position: absolute;
    transform: scale(.75);
    margin: -3px 0px 0 -3px;
    border: 2px solid var(--clr-purple);
    border-radius: 50%;
    transition: var(--transition) !important;
}

.swiper-backface-hidden .swiper-slide {
    display: flex;
    justify-content: center;
}


.pack-swiper .swiper-slide {
    min-width: 410px !important;
}