.embla {
    max-width: 48rem;
    margin: auto;
    --slide-height: 31rem;
    --slide-spacing: 1rem;
    /* --slide-size: 90%; */
    --slide-size: 100%;
    cursor: pointer;
}

.embla__viewport {
    overflow: hidden;
}

.embla__container {
    backface-visibility: hidden;
    display: flex;
    touch-action: pan-y;
    margin-left: calc(var(--slide-spacing) * -1);
}

.embla__slide {
    flex: 0 0 var(--slide-size);
    min-width: 0;
    padding-left: var(--slide-spacing);
}

.embla__slide__img {
    /* border-radius: 10px; */
    display: block;
    height: var(--slide-height);
    width: 100%;
    object-fit: cover;
}

.embla__controls {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: -1.4rem 2rem 0px 0px;
}

@media screen and (max-width:768px) {
    .embla__controls {
        justify-content: center;
        margin: -3rem 0rem 0px 0px;
    }
}

.embla__buttons {
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    gap: 0.6rem;
    align-items: center;
}

.embla__button {
    /* -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5); */
    appearance: none;
    background-color: var(--clr-light-blue-2) !important;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    box-shadow: inset 0 0 0 0.1rem var(--clr-purple);
    width: 2.7rem;
    height: 2.7rem;
    z-index: 1;
    border-radius: 50%;
    color: var(--clr-purple);
    display: flex;
    align-items: center;
    justify-content: center;
}

.embla__button:disabled {
    color: var(--detail-high-contrast);
}

.embla__button__svg {
    width: 35%;
    height: 35%;
}

.embla__dots {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;
    align-items: center;
    margin-right: calc((2.6rem - 1.4rem) / 2 * -1);
}

.embla__dot {
    -webkit-tap-highlight-color: rgba(var(--text-high-contrast-rgb-value), 0.5);
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    touch-action: manipulation;
    display: inline-flex;
    text-decoration: none;
    cursor: pointer;
    border: 0;
    padding: 0;
    margin: 0;
    width: 2.6rem;
    height: 2.6rem;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
}

.embla__dot:after {
    box-shadow: inset 0 0 0 0.2rem var(--detail-medium-contrast);
    width: 1.4rem;
    height: 1.4rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    content: '';
}

.embla__dot--selected:after {
    box-shadow: inset 0 0 0 0.2rem var(--text-body);
}