.home-brand-logo {
  transform: scale(1.35);
  border-radius: 2px;
}

.home-navbar-item>a {
  color: var(--clr-purple) !important;
  letter-spacing: 1px;
}

.home-navbar-item:hover,
.home-navbar-item:hover>a,
.home-navbar-item>div>a:hover {
  color: var(--clr-pink) !important;
}

.home-navbar-item::after,
.active-link::after {
  content: "";
  width: 0;
  height: 3px;
  top: 1px;
  display: block;
  background-color: var(--clr-pink);
  transition: width 0.7s;
  border-radius: 20px;
}

.home-navbar-item::after {
  width: 0;
}

.home-navbar-item:hover:after {
  width: 100%;
}

.active-link {
  color: var(--clr-pink) !important;
}

.active-link::after {
  width: 100%;
}


.navbar-toggler {
  border: 2px solid var(--clr-purple) !important;
  border-radius: 10px !important;
  height: 46px !important;
  width: 46px !important;
  box-shadow: 0 0.1rem 0.9rem 0 rgb(220 244 255);
  outline: none !important;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  right: 4%;
  background-color: var(--clr-light) !important;
}


.navbar-toggler-icon {
  transform: scale(1.6);
}

.navbar-toggler-icon {
  background-image: url('../../../assets/images/nav/menu.png') !important;
}

.navbar-toggler:focus {
  outline: none !important;
  box-shadow: var(--shadow-1) !important;
}

.rose-bleu-logo {
  height: 60px;
  transition: var(--transition);
  transform: scale(1.4);
}

.rose-bleu-logo:hover {
  opacity: 0.8;
}

.navbar-icon {
  color: var(--clr-dark) !important;
  font-size: 1.7rem !important;
  cursor: pointer;
  z-index: 9999 !important;
  position: relative;
}

.navbar-icon:hover {
  color: var(--yellow) !important;
}

.icon-items {
  margin: 0 -3px 0 0px !important;
  z-index: 1;
  position: sticky;
}

.icon-items .row:nth-child(2) {
  margin: 0 -4rem !important;
}

@media screen and (min-width:992px) {
  .icon-items {
    margin: 0 -28px 0 0px !important;
  }
}

@media screen and (max-width:578px) {
  .icon-items .row:nth-child(2) {
    margin: 0 0rem !important;
  }

}

.badge-card {
  z-index: 9999 !important;
}


.navbar-link {
  transition: all .7s;
  border-radius: var(--radius) !important;
  padding: 6px 10px !important;
}

.navbar-link a:hover {
  background-color: var(--clr-light-yellow) !important;
  color: var(--clr-dark) !important;
}

.navbar-link:hover {
  color: var(--clr-dark) !important;
}


.bar-icon::after {
  content: "" !important;
  position: absolute !important;
  height: 20px !important;
  width: 2px !important;
  color: var(--clr-dark) !important;
  background-color: var(--clr-dark) !important;
  margin: 8px 0 0 3px !important;
}


.dropdown-menu.show {
  background-color: var(--clr-light) !important;
  min-width: 180px !important;
}


@media screen and (max-width:768px) {
  .dropdown-item {
    padding: 0px 10px !important;
  }
}

.dropdown-menu {
  transition: var(--transition) !important;
}

.navbar-nav li.dropdown-submenu:hover>ul.dropdown-menu {
  display: block !important;
  position: absolute !important;
}


.dropdown-menu {
  border: none !important;
  border-top: 2px var(--yellow) solid !important;
  background-color: var(--clr-light) !important;
  box-shadow: var(--shadow-2) !important;
  margin: .0rem 0 0 0 !important;
}

.sub-dropdown-menu {
  margin: -2rem 0 0 15rem !important;
  position: absolute !important;
}

.dropdown-menu-categorie {
  width: 30rem !important;
  max-height: 47rem;
  background-color: var(--clr-light) !important;
  overflow-y: scroll;
  padding: 1rem !important;
  z-index: 9999 !important;
}

.dropdown-menu li {
  display: block !important;
  padding: 11px 20px 0px 20px !important;
  margin: 2px 0 -7px -8px !important;
}

.nav-item-title {
  width: 205px !important;
}

.nav-item-title p {
  font-size: 14px;
  padding: .3rem 0 1rem 0;
}

.dropdown-item {
  display: block !important;
  padding: 5px 10px !important;
  clear: both !important;
  font-weight: 400 !important;
  line-height: 1.5 !important;
  color: var(--clr-dark) !important;
  text-align: inherit !important;
  white-space: initial !important;
  background-color: transparent !important;
  border: 0 !important;
  transition: all .5s !important;
  min-width: 18rem !important;
}

.nav-item-marque-image {
  width: 100%;
  height: 100%;
  border-radius: var(--radius);
  box-shadow: var(--shadow-1);
  object-fit: fill !important;
  transition: var(--transition);
}

.nav-item-marque-image:hover {
  transform: scale(1.2);
}


/* search-box */

.search-box {
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  width: 98%;
  background: var(--clr-light);
  border-radius: 10px;
  box-shadow: var(--shadow-2);
  padding: 1rem;
  font-family: var(--font-family-lato-bold);
  opacity: 0;
  height: 0;
  overflow: hidden;
  transition: opacity 0.7s ease, height 0.7s ease, margin 0.7s ease, all 0.7s ease;
  margin: -2rem 0 2rem 0rem !important;
}

.search-box.show {
  opacity: 1;
  height: auto;
  max-height: 80vh;
  overflow: scroll;
  padding: 1rem;
  margin: 2rem 0 7rem 0rem !important;
}

.input-group-append {
  margin-top: -2.3rem;
}

.search-box-title {
  color: var(--gray-2);
  opacity: 0.8;
}

.search-box-icon {
  font-size: 25px;
  color: var(--clr-purple);
  cursor: pointer;
  transition: var(--transition);
}

.search-box-icon:hover {
  color: var(--clr-dark);
}

.search-box-input {
  width: 100%;
  border: 1px solid var(--clr-light-dark);
  border-radius: var(--radius);
  margin-top: 1.7rem;
  outline: none !important;
  font-size: 20px;
  transition: var(--transition);
  height: 2.9rem;
}

.display-all {
  border-radius: var(--radius) !important;
  /* width: 20rem !important; */
  width: auto !important;
  padding: .8rem 1.1rem !important;
  border: 0.15rem solid var(--clr-purple) !important;
  text-transform: uppercase !important;
  margin: 0 0px 0px 0 !important;
  color: var(--clr-purple) !important;
  background-color: var(--clr-light-purple) !important;
  transition: var(--transition) !important;
}

.display-all:hover {
  color: var(--clr-light) !important;
  background-color: var(--clr-purple) !important;
}


@media screen and (min-width: 1400px) and (max-width: 1510px) {
  .search-box-input {
    width: 90%;
    margin-left: 1rem;
  }
}

@media screen and (max-width: 1400px) {
  .search-box-input {
    width: 100%;
  }
}

.search-box-input:focus {
  border: 1px solid var(--clr-light-dark) !important;
  box-shadow: none !important;
}

.search-box-input::placeholder {
  font-size: 19px;
  color: var(--gray);
}

.fs-6 {
  font-size: 1.3rem !important;
}


@media screen and (max-width: 1400px) and (min-width:992px) {

  .search-col {
    top: 0px !important;
    position: absolute !important;
    left: 18% !important;
  }
}

@media screen and (max-width: 1200px) {

  .home-navbar-item>a,
  .home-navbar-item {
    letter-spacing: 0px;
    font-size: 14px !important;
  }
}

@media screen and (max-width: 992px) {
  .home-navbar-item {
    font-size: 14px !important;
  }
}

@media screen and (max-width: 991px) {
  .search-col {
    margin-bottom: 1rem !important;
  }

  .home-navbar-item {
    font-size: 15px !important;
  }
}

@media screen and (max-width: 1400px) {
  .navbar-toggler {
    outline: none !important;
  }

  .navbar-brand {
    margin: 0rem 0 0 1rem !important;
  }

  .dropdown-menu li {
    padding: 11px 0px 0px 20px !important;
  }

}

@media screen and (max-width: 692px) {
  .search-box-input {
    margin-top: .6rem;
  }
}

.w-max-content {
  width: max-content;
}