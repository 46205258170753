@media screen and(max-width:768px) {
    .brand-navigation .swiper-slide {
        width: 340px !important;
    }
}

.container-centent {
    max-width: 90%;
    overflow-y: scroll;
}


.brand-swiper-content {
    width: 350px !important;
    height: 215px !important;
}

.brand-swiper {
    width: 275px !important;
    height: 170px !important;
    border-radius: var(--radius-2);
    box-shadow: var(--shadow-pink);
    overflow: hidden;
    z-index: 999;
    border: 2px solid #f5f0ff;
    transition: var(--transition);
    position: relative;
    margin-top: 1.2rem;
}

.brand-navigation .swiper-pagination {
    margin: 0rem 0 0px 0 !important;
    height: 1rem !important;
    display: none !important;
}

.brand-swiper-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: var(--transition);
}


.brand-swiper-overlay {
    position: absolute;
    background-color: var(--clr-dark);
    width: 100%;
    opacity: .8;
    transform: translate(-50%, 0);
    bottom: 0;
    height: 0rem;
    left: 50%;
    color: var(--clr-light);
    transition: var(--transition);
}


.brand-swiper-overlay-before {
    position: absolute;
    background-color: var(--clr-dark);
    width: 100%;
    opacity: .5;
    transform: translate(-50%, 0);
    top: 0;
    height: 100%;
    left: 50%;
    color: var(--clr-light);
    transition: var(--transition);
}

.brand-swiper:hover .brand-swiper-overlay-before,
.marque-image-container:hover .brand-swiper-overlay-before {
    height: 0%;
}

.brand-swiper:hover .brand-swiper-overlay,
.marque-image-container:hover .brand-swiper-overlay {
    height: 100%;
    opacity: .9;
}

.brand-swiper-title {
    position: absolute;
    color: var(--clr-dark);
    width: 100%;
    margin: 0rem 0 -5px 0;
    bottom: 0px;
    height: 2rem;
}

.brand-swiper-title p {
    font-size: 15px !important;
    text-align: center;
}

.brand-swiper-logo {
    /* width: 75%; */
    height: 100%;
    object-fit: scale-down;
    position: absolute;
    z-index: 9 !important;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transition: var(--transition);
    padding: 7px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
    font-size: 30px !important;
}