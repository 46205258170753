#progress {
    position: fixed;
    bottom: 30px;
    right: 30px;
    height: 50px;
    width: 50px;
    display: none;
    place-items: center;
    border-radius: 50%;
    box-shadow: var(--shadow-1);
    cursor: pointer;
    z-index: 999999;
}

#progress-value {
    display: block;
    height: 91%;
    width: 91%;
    background-color: #ffffff;
    border-radius: 50%;
    display: grid;
    place-items: center;
    font-size: 35px;
    color: #001a2e;
}

.scroll-to-top-icon {
    font-size: 27px;
    color: var(--clr-purple);
    opacity: .8;
}

.scroll-to-top-icon:hover {
    opacity: 1;
}