.css-1mm5wr7-MuiChip-root {
    background-color: var(--clr-light-blue) !important;
    border-radius: 8px;
    transition: var(--transition) !important;
}

.MuiBreadcrumbs-li:nth-child(1) .css-1mm5wr7-MuiChip-root {
    background-color: var(--clr-blue) !important;
}

.MuiBreadcrumbs-li:nth-child(1) a {
    color: var(--clr-light-blue);
    background-color: var(--clr-blue) !important;
}

.MuiBreadcrumbs-li:nth-child(1) .MuiChip-icon {
    color: var(--clr-light-blue) !important;
}


.MuiBreadcrumbs-li a {
    cursor: pointer !important;
    color: var(--clr-blue);
    background-color: var(--clr-light-blue);
}

.MuiBreadcrumbs-li:hover a {
    color: var(--clr-light-blue) !important;
    background-color: var(--clr-blue) !important;
}


.css-1mm5wr7-MuiChip-root:hover {
    background-color: var(--clr-blue) !important;
}

.css-1mm5wr7-MuiChip-root .MuiChip-icon {
    color: var(--clr-blue) !important;
    transition: var(--transition) !important;
}

.css-1mm5wr7-MuiChip-root:hover .MuiChip-icon {
    color: var(--clr-light-blue) !important;
}