  .gallery-parent .swiper {
      width: 100%;
      height: 100%;
  }

  .gallery-parent .swiper-slide {
      text-align: center;
      font-size: 18px;
      background: #fff;

      /* Center slide text vertically */
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .gallery-parent .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }

  .gallery-parent .swiper {
      width: 100%;
      height: 300px;
      margin-left: auto;
      margin-right: auto;
  }

  .gallery-parent .swiper-slide {
      background-size: cover;
      background-position: center;
  }

  .gallery-parent .mySwiper2 {
      height: 100%;
      width: 100%;
  }

  .gallery-parent .mySwiper {
      height: 20%;
      box-sizing: border-box;
      padding: 10px 0;
  }

  .gallery-parent .mySwiper .swiper-slide {
      width: 25%;
      height: 100%;
      /* opacity: 0.4; */
  }

  .gallery-parent .mySwiper .swiper-slide-thumb-active {
      opacity: 1;
  }

  .gallery-parent .swiper-slide img {
      display: block;
      width: 100%;
      height: 100%;
      object-fit: cover;
  }


  .gallery-parent .swiper-button-prev,
  .gallery-parent .swiper-button-next {
      color: black !important;
  }