.shopping-cart-item {
    border: 1px solid #f5f5f5;
}

.shopping-cart-item-content {
    height: 7rem;
    position: relative;
    overflow: hidden;
}

.shopping-cart-item-image {
    height: 6rem !important;
}

@media screen and (max-width:768px) {
    .shopping-cart-item-content {
        height: 14rem;
    }
}

@media screen and (max-width:578px) {
    .shopping-cart-item-content {
        height: 15rem;
    }
}

.shopping-cart-item-img {
    width: 100%;
    margin: 0 0 0 0.6rem;
    object-fit: cover !important;
    overflow: hidden;
    height: 100%;
}

.shopping-cart-item-quantity {
    box-shadow: var(--shadow-2);
    height: 44px;
    width: 7rem;
    border-radius: var(--radius-2);
    padding-top: 1%;
}

.shopping-cart-item-quantity-value {
    font-family: var(--font-family-outfit-reg);
    font-weight: 600;
}

.shopping-cart-item-quantity-icon {
    cursor: pointer;
    color: var(--clr-dark);
    font-size: 25px;
    border: var(--border-solid);
    border-radius: 50%;
}

.shopping-cart-item-title {
    font-size: 14px;
}

.shopping-cart-item-prix {
    font-size: 14px;
}

.trash-icon {
    color: var(--clr-danger) !important;
    font-size: 20px;
    box-shadow: var(--shadow-3);
    border-radius: var(--radius);
    width: 25px;
    height: 25px;
    cursor: pointer;
    transition: var(--transition) !important;
    margin: 0 0 0 1px;
}

.trash-icon:hover {
    transform: scale(1.1);
}

.trash {
    margin: 0 0 0 -15px;
    transform: translate(12%, -17%);
}