.about-us .top-image {
    /* background: url('../../../assets/images/apropos-family-2.webp'); */
    background: linear-gradient(to bottom,
            rgba(0, 0, 0, 0),
            rgba(0, 0, 0, 0.5)), url('../../../assets/images/apropos-family-2.webp');

}

@media screen and (max-width:768px) {
    .about-us .top-image {
        background-position: top !important;
    }
}


.our-Offre-card-overlay {
    position: absolute;     
    background-color: var(--clr-blue);  
    width: 100%;
    opacity: .8;
    transform: translate(-50%, 0);
    bottom: 0;
    height: 0%;
    left: 50%;
    color: var(--clr-light);
    transition: var(--transition);
    z-index: -1 !important;
}

.our-Offre-card .our-Offre-card-desc {
    transition: var(--transition);
}

.our-Offre-card:hover .our-Offre-card-overlay {
    height: 100%;
    opacity: .8;
}

.our-Offre-card:hover .our-Offre-card-title {
    color: var(--clr-dark) !important;
    font-weight: 600 !important;
}

.our-Offre-card:hover .our-Offre-card-desc {
    color: var(--clr-light) !important;
}