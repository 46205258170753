.filter {
    height: auto;
    border: 1px solid #f2f0ff;
    border-radius: 10px;
    max-height: 90vh;
    overflow-x: hidden;
    overflow-y: scroll;
    top: 3px;
    position: relative;
}


.filter-title {
    color: var(--clr-dark);
    font-size: 14px;
    letter-spacing: .9px;
}

.accordion-item {
    border: none !important;
    width: 100% !important;
    box-shadow: none !important;
    background: none !important;
}

.accordion-button {
    font-size: 14px !important;
    letter-spacing: 1px !important;
    opacity: .9 !important;
    padding: 12px 0 0px 20px !important;
    transition: var(--transition) !important;
    color: var(--clr-dark) !important;
}

.accordion-button:hover {
    color: var(--clr-purple) !important;
}

.accordion-header,
.accordion-button {
    box-shadow: none !important;
    background: none !important;
    transition: var(--transition) !important;
    text-transform: uppercase !important;
}

.accordion {
    box-shadow: none !important;
    background: none !important;
    background-color: transparent !important;
}

.accordion-button::after {
    opacity: .7 !important;
    transform: scale(.5) !important;
}

.accordion-body {
    color: var(--clr-purple) !important;
    font-size: 14px !important;
    padding: 10px 0 0 40px !important;
    letter-spacing: 1px;
}

.accordion-body li {
    padding: 0 0 5px 0 !important;
    text-transform: uppercase !important;
    transition: var(--transition) !important;
}

.accordion-body li:hover {
    padding-left: 10px !important;
    color: var(--clr-pink) !important;
}

.accordion-button:hover {
    box-shadow: none !important;
    background: none !important;
}

.filter-price-btn {
    font-family: var(--font-family-lato-reg) !important;
    transition: var(--transition) !important;
    font-weight: 600 !important;
    letter-spacing: 1px;
    outline: none !important;
    border: none !important;
}

.filter-price-btn:hover {
    box-shadow: none !important;
    background: none !important;
    color: var(--clr-purple) !important;
    font-family: var(--font-family-varela-round) !important;
}

.filter-price-value {
    font-size: 14px !important;
    font-family: var(--font-family-montserrat-reg) !important;
    font-weight: 600 !important;
    opacity: 0.9 !important;
}

.filter-tri span,
.filter-result {
    text-transform: uppercase;
}

.hidden {
    display: none !important;
}

.accordion-body-marque {
    padding: 0px 0 0 40px !important;
}

.accordion-body-marque li {
    padding: 0 0 0px 0 !important;
}

.accordion-body-marque li:hover {
    padding: 0 0 0 10px !important;
}

.accordion-body-marque:first-child {
    /* padding: 10px 0 0 40px !important; */
    margin: 10px 0 0 0 !important;
}

.select-option,
.select-option option {
    font-family: var(--font-family-outfit-reg) !important;
}

.select-option:focus {
    box-shadow: none !important;
}

.select-option option:first-child {
    margin: 10px 0 0 0 !important;
    padding: 10px 0 0 0 !important;
}


.gthan {
    color: var(--clr-dark);
    opacity: .6;
    transform: rotate(80deg);
}

.categorie-filter {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}

@media screen and (min-width:768px) {
    .filter {
        min-height: 42rem !important;
    }
}

@media screen and (max-width:768px) {
    .filter {
        margin-top: -2rem !important;
    }
}

.filter-title-span::after {
    border-bottom: 0;
    border-left: .3em solid #0000;
    border-right: .3em solid #0000;
    border-top: .3em solid;
    content: "";
    display: inline-block;
    margin-left: .255em;
    vertical-align: .255em;
}