.boutique {
    /* transform: scale(.98); */
    /* margin: 0 0 2rem 0; */
}

.boutique .container {
    max-width: 1600px !important;
}

@media screen and (max-width:1526px) and (min-width:1400px) {
    .boutique .product-info {
        margin: -0.8rem 0 0 0;
    }

    .boutique .view {
        margin: -8rem 0 1rem 0;
    }

    .boutique .submit-btn {
        font-size: 12px !important;
        padding: 14px 2rem !important;
        margin: -2rem 0 0.3rem 0 !important;
        transform: scale(1.1);
    }
}

@media screen and (max-width:880px) and (min-width:768px) {
    .boutique .submit-btn {
        font-size: 12px !important;
        padding: 14px 2rem !important;
        margin: -2rem 0 0.3rem 0 !important;
        transform: scale(1.1);
    }
}

@media screen and (max-width:590px) and (min-width:100px) {
    .boutique.isgrid .product-info {
        margin: -1.4rem 0 0 0;
        height: 6rem;
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .boutique.isgrid a .product-title {
        font-size: 15px !important;
    }

    .boutique.isrow .product-info {
        height: 5rem;
    }

    .boutique.isgrid .view {
        margin: -5rem 0 4.2rem 0;
        transform: scale(1.2);
    }

    .boutique.isgrid .submit-btn {
        font-size: 60% !important;
        padding: 14px 12% !important;
        margin: -2rem 0 0.3rem 0 !important;
        transform: scale(1.1);
    }

    .product-item.isrow {
        max-height: 26rem !important;
        min-height: 26rem !important;
    }

    .product-item {
        max-width: 360px !important;
    }

    .add-to-cart-container.isgrid {
        min-height: 6.2rem;
    }

    .variant-row-m2.isgrid {
        margin: 0px 0px 6.3rem 0 !important;
    }

}