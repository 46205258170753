.loader-parent {
	/* background-image: url('../../../assets/images/back-2.webp'); */
	/* background-image: url('../../../assets/images/back-3.jpg'); */
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	width: 100%;
	height: 100%;
}

.loading-text {
	font-family: var(--font-family-outfit-reg);
}


.lazy-loading-img {
	width: 13rem;
	position: relative;
}


/* ======================= */


.loading-more {
	--dim: 3.4rem;
	width: var(--dim);
	height: var(--dim);
	position: relative;
	animation: fetchmore 1.3s linear infinite;
}

.loading-more .circle {
	--dim: 1.2rem;
	width: var(--dim);
	height: var(--dim);
	background-color: var(--clr-dark);
	border-radius: 40%;
	position: absolute;
}

.loading-more .circle:nth-child(1) {
	top: 0;
	left: 0;
}

.loading-more .circle:nth-child(2) {
	top: 0;
	right: 0;
}

.loading-more .circle:nth-child(3) {
	bottom: 0;
	left: 0;
}

.loading-more .circle:nth-child(4) {
	bottom: 0;
	right: 0;
}

@keyframes fetchmore {
	0% {
		transform: rotate(0);
	}


	100% {
		transform: rotate(360deg);
	}
}


/* ======================================================= */

@keyframes isloading {
	from {
		opacity: 0;
	}

	to {
		opacity: 1;
	}
}

.is-loader {
	margin: -2rem 0px 12px 3rem !important;
}

.square {
	background: var(--clr-dark);
	width: 10px;
	height: 10px;
	position: absolute;
	border-radius: var(--radius);
	/* top: 50%;
	left: 50%;
	margin-top: -5px;
	margin-left: -5px; */
}

#sq1 {
	margin-top: -25px;
	margin-left: -25px;
	animation: isloading 675ms ease-in-out 0s infinite alternate;
}

#sq2 {
	margin-top: -25px;
	animation: isloading 675ms ease-in-out 75ms infinite alternate;
}

#sq3 {
	margin-top: -25px;
	margin-left: 15px;
	animation: isloading 675ms ease-in-out 150ms infinite;
}

#sq4 {
	margin-left: -25px;
	animation: isloading 675ms ease-in-out 225ms infinite;
}

#sq5 {
	animation: isloading 675ms ease-in-out 300ms infinite;
}

#sq6 {
	margin-left: 15px;
	animation: isloading 675ms ease-in-out 375ms infinite;
}

#sq7 {
	margin-top: 15px;
	margin-left: -25px;
	animation: isloading 675ms ease-in-out 450ms infinite;
}

#sq8 {
	margin-top: 15px;
	animation: isloading 675ms ease-in-out 525ms infinite;
}

#sq9 {
	margin-top: 15px;
	margin-left: 15px;
	animation: isloading 675ms ease-in-out 600ms infinite;
}


/* ============================== */

.dots {
	width: var(--size);
	height: var(--size);
	position: relative;
	border-radius: 50%;
	box-shadow: var(--shadow);
	margin: -1rem 0px 0px 0px;
}

.dot {
	width: var(--size);
	height: var(--size);
	animation: dwl-dot-spin calc(var(--speed) * 5) infinite linear both;
	animation-delay: calc(var(--i) * var(--speed) / (var(--dot-count) + 2) * -1);
	rotate: calc(var(--i) * var(--spread) / (var(--dot-count) - 1));
	position: absolute;
}

.dot::before {
	content: "";
	display: block;
	width: var(--dot-size);
	height: var(--dot-size);
	background-color: var(--color);
	border-radius: 50%;
	position: absolute;
	transform: translate(-50%, -50%);
	bottom: 0;
	left: 50%;
}

@keyframes dwl-dot-spin {
	0% {
		transform: rotate(0deg);
		animation-timing-function: cubic-bezier(0.390, 0.575, 0.565, 1.000);
		opacity: 1;
	}

	2% {
		transform: rotate(20deg);
		animation-timing-function: linear;
		opacity: 1;
	}

	30% {
		transform: rotate(180deg);
		animation-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);
		opacity: 1;
	}

	41% {
		transform: rotate(380deg);
		animation-timing-function: linear;
		opacity: 1;
	}

	69% {
		transform: rotate(520deg);
		animation-timing-function: cubic-bezier(0.445, 0.050, 0.550, 0.950);
		opacity: 1;
	}

	76% {
		opacity: 1;
	}

	76.1% {
		opacity: 0;
	}

	80% {
		transform: rotate(720deg);
	}

	100% {
		opacity: 0;
	}
}