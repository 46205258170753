.top-header-container {
    background-color: var(--clr-pink) !important;
    height: 50px !important;
    color: var(--clr-light) !important;
    font-family: var(--font-family-outfit-reg) !important;
    border-bottom: 1px solid var(--clr-light-dark-2);
    position: relative;
    /* z-index: 9; */
}

@media screen and (min-width:578px) {
    .top-header-container .top-container {
        margin: 0 2rem !important;
    }
}


.top-header-icon {
    color: var(--clr-light) !important;
    font-size: 1.8rem !important;
    cursor: pointer;
    z-index: 9 !important;
    position: relative;
}

.navbar-icon:hover {
    color: var(--clr-pink) !important;
}


.top-containerr {
    max-width: 1700px !important;
}

.info>a,
.social>a,
.time {
    transition: var(--transition) !important;
}

.info>a:hover,
.icon:hover {
    color: var(--clr-light) !important;
    /* margin-bottom: 3px !important; */
}

.social-media-content {
    width: 0px;
    height: 11rem;
    position: absolute;
    overflow: hidden !important;
    background-color: var(--clr-light);
    box-shadow: var(--shadow-1);
    border-radius: var(--radius);
    padding: 3px 0 0 0px;
    transition: var(--transition);
}

.is-social-media-open {
    visibility: visible;
    z-index: 999 !important;
    margin: 3rem 0px 0 -1%;
    width: 50px;
    padding: 3px 0 0 7px;
}

.is-social-media-close {
    /* height: 0px; */
    visibility: hidden;
    z-index: 0 !important;
    margin: 2.7rem 0px 0 5.4rem;

}

.social-media-image {
    width: 40px;
    margin: 0 0 3px 0;
}

.free-shipping-img {
    width: 40px;
}

.social-media-content .social-icon {
    margin-top: .4rem;
    border: 2px solid var(--clr-dark);
    border-radius: 50%;
    width: 35px;
    height: 35px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px 0px 1px 1px;
    transition: var(--transition);
}


.social-media-content .social-icon:hover {
    border: 2px solid var(--clr-pink);
    color: var(--clr-pink) !important;
}

.social-media-grid-icon {
    z-index: 999 !important;
}

.card-badge {
    width: 22px !important;
    height: 22px !important;
    margin: 0px 0px 0px 2px !important;
    padding: 3px 0 0 0 !important;
    font-size: 15px !important;
    font-family: var(--font-family-outfit-medium);
}

@media screen and (max-width: 1400px) {
    .top-header-container {
        /* height: auto !important; */
        height: 50px !important;
    }
}


@media screen and (min-width:992px) {
    .m-info {
        margin: 0 0 0 -16px !important;
    }

    .m-social {
        margin: 0 -24px 0 -3px !important;
    }
}