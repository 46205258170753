.container-centent {
    max-width: 90%;
    overflow-y: scroll;
}

.subcategory-navigation .swiper-slide {
    width: 250px !important;
}




.subcategory-swiper-content {
    width: 220px;
    height: 230px;
}


.subcategory-swiper {
    width: 220px !important;
    height: 190px !important;
    /* height: 150px !important; */
    animation: borderChange 7s linear infinite;
    z-index: 999;
    transition: var(--transition);
    position: relative;
    /* margin-top: 1.2rem; */
    /* border: 2px solid #073cfd !important; */
}

.subcategory-swiper::before {
    content: "";
    position: absolute;
    width: 7rem;
    height: 18rem;
    /* background: linear-gradient(#00CCFF, #d400D4); */
    /* background: linear-gradient(var(--clr-blue), var(--clr-purple)); */
    background-color: var(--clr-purple);
    transform: rotate(50deg);
    /* animation: animate 5s linear infinite; */
    transition: var(--transition);
}

.subcategory-swiper::after {
    content: "";
    position: absolute;
    /* inset: 4px; */
    background: transparent;
    border-radius: 20px;
}

.subcategory-swiper:hover::before {
    /* animation: animate 5s linear infinite; */
    width: 10rem;
}

.subcategory-swiper:hover {
    padding: 3px;
}

@keyframes animate {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}




.subcategory-navigation .swiper-pagination {
    margin: 0rem 0 0px 0 !important;
    height: 1rem !important;
    display: none !important;
}

.subcategory-swiper-img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    position: relative;
}


@media screen and (max-width:644px) {
    .subcategory-navigation .swiper-slide {
        width: 190px !important;
    }

    .subcategory-swiper-content {
        width: 180px;
        height: 160px;
    }

    .subcategory-swiper {
        width: 190px !important;
        height: 160px !important;
        margin-top: 0rem;
    }

}


/* .subcategory-swiper-img::before {
    content: '';
    position: absolute;
    width: 180px;
    height: 140%;
    background-color: var(--clr-blue);
    box-shadow: 0 0 20px rgb(8, 8, 8);
}

.subcategory-swiper-img::after {
    content: '';
    position: absolute;
    inset: 10px;
    background-color: var(--clr-blue);
    box-shadow: 0 0 20px rgb(4, 75, 97);
    border-radius: 10px;
} */




/* .subcategory-swiper {
    transform: scale(.6);
} */

/* .subcategory-swiper:hover {
    transform: scale(1.2);
} */


.subcategory-swiper-overlay {
    position: absolute;
    background-color: var(--clr-dark);
    width: 100%;
    opacity: .8;
    transform: translate(-50%, 0);
    bottom: 0;
    height: 0rem;
    left: 50%;
    color: var(--clr-light);
    transition: var(--transition);
}


.subcategory-swiper-overlay-before {
    position: absolute;
    background-color: var(--clr-dark);
    width: 100%;
    opacity: .5;
    transform: translate(-50%, 0);
    top: 0;
    height: 100%;
    left: 50%;
    color: var(--clr-light);
    transition: var(--transition);
}

.subcategory-swiper:hover .subcategory-swiper-overlay-before,
.subcategory-image-container:hover .subcategory-swiper-overlay-before {
    height: 0%;
}

.subcategory-swiper:hover .subcategory-swiper-overlay,
.subcategory-image-container:hover .subcategory-swiper-overlay {
    height: 100%;
    opacity: .9;
}

.subcategory-swiper-title {
    position: absolute;
    color: var(--clr-dark);
    width: 100%;
    margin: 0rem 0 -5px 0;
    bottom: 0px;
    height: 2rem;
}

.subcategory-swiper-title p {
    font-size: 15px !important;
    text-align: center;
}



@keyframes borderChange {
    0% {
        border-color: var(--clr-light);
    }

    50% {
        border-color: var(--clr-light-primary);
    }

    70% {
        border-color: var(--clr-light-warning);
    }

    100% {
        border-color: var(--clr-light-info);
    }
}

.subcategory-swiper .swiper-button-prev,
.subcategory-swiper .swiper-button-next {
    display: none !important;
    background-color: var(--clr-light) !important;
    border-radius: 50% !important;
    width: 43px !important;
    height: 43px !important;
    border: 1px solid #272b3a26 !important;
    transition: var(--transition);
}

.subcategory-swiper .swiper-button-prev:after,
.subcategory-swiper .swiper-button-next:after {
    color: var(--clr-dark) !important;
    font-size: 21px !important;
    font-weight: 600 !important;
    transition: var(--transition);
}

.subcategory-swiper .swiper-button-prev:hover,
.subcategory-swiper .swiper-button-next:hover {
    border: 1px solid var(--clr-purple) !important;
}

.subcategory-swiper .swiper-button-prev:hover .swiper-button-prev:after,
.subcategory-swiper .swiper-button-next:hover .swiper-button-next:after {
    color: var(--clr-light) !important;
    font-size: 27px !important;
}

/* @media screen and (max-width: 768px) {
    .subcategory-navigation .swiper-slide {
        width: 350px !important;
    }

    .subcategory-swiper-content {
        width: 100% !important;
        height: 225px !important;
    }

    .subcategory-swiper {
        width: 100% !important;
        height: 160px !important;
    }

    .swiper-slide {
        margin: 0px 10px;
    }
} */