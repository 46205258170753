.product-img,
.product-title,
.pack-img,
.pack-title {
    transition: var(--transition) !important;
    cursor: pointer;
}

.product-item {
    height: 28rem;
    border: var(--border-light-purple);
    border-radius: 10px;
    transform: scale(.95);
    max-width: 310px !important;
    overflow: hidden;
    background-color: var(--clr-light);
}

.product-img:hover {
    transform: scale(1.1);
}

.product-image {
    position: relative;
    margin: 0px 0 -3.6rem 0 !important;
    padding: 0 !important;
}

.product-title {
    font-size: 15px !important;
    opacity: 0.9 !important;
    font-weight: 600;
}

.vr-line-height {
    line-height: 1.1rem;
}

.pr-line-height {
    line-height: 1.4rem;
}


.product-info {
    margin: -12px 0 0 0;
    height: 3rem;
    color: var(--clr-dark);
}

.product-price {
    color: var(--clr-purple);
    font-size: 23px;
}

.discount-price {
    color: var(--clr-dark) !important;
    font-size: 18px !important;
    opacity: .8 !important;
}

.display-all-variant {
    width: 100%;
    height: auto;
    min-height: 10rem;
    border-radius: var(--radius-2);
    box-shadow: var(--shadow-5);
    z-index: 9999 !important;
    position: relative;
    background-color: var(--clr-light);
}

.more-variant-icon {
    position: absolute;
    top: 25px;
    color: var(--clr-danger);
    transition: var(--transition);
    width: 56px !important;

}

.more-variant-icon-grid-left {
    left: 12.5rem;
}

.more-variant-icon-row-left {
    left: 13.7rem;
}

.more-variant-icon:hover {
    color: var(--clr-blue);
    transform: rotate(90deg);
}

@media screen and (max-width:768px) {
    .more-variant-icon-grid-left {
        left: 7.5rem !important;
    }
}

.discount-tag {
    width: 3.1rem;
    right: 0px;
    z-index: 9;
}

.discount-value-old {
    left: 0px;
    padding: 5px 10px;
    z-index: 9;
    border-bottom-right-radius: var(--radius);
    background-color: var(--clr-blue);
    color: var(--clr-light);
    font-size: 19px;
}

.discount-value {
    background-color: var(--clr-red);
    border-bottom-right-radius: var(--radius);
    color: var(--clr-light);
    font-size: 19px;
    top: 0;
    left: 0;
    padding: 0px 4px;
    font-family: var(--font-family-varela-round);
    z-index: 9;
}




@keyframes rotateAnimation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}


.add-to-cart-container {
    transform: scale(.85);
    min-height: 4.2rem;
    max-height: 4.2rem;
    transition: var(--transition) !important;
}

.add-to-cart-container:hover .addtocart-btn {
    transform: scale(1.1);
}

.add-to-cart {
    text-transform: uppercase !important;
    color: var(--clr-light) !important;
    font-size: 14px;
    text-align: center;
    background-color: var(--clr-blue);
    padding: 10px 20px;
    border-radius: var(--radius);
    letter-spacing: .8px;
}

.add-to-cart:hover {
    background-color: var(--clr-dark) !important;
}

.p-discount-price {
    margin: -5px 0 0 0 !important;
}


@media screen and (max-width:768px) and (min-width:576px) {
    .populare-products .product-info {
        margin: -2.6rem 0 0 0;
    }


    .populare-products .product-price {
        margin: 0 0 3.5rem 0;
    }

    .populare-products .submit-btn {
        font-size: 12px !important;
        padding: 14px 2rem !important;
        margin: -2rem 0 0.3rem 0 !important;
        transform: scale(1.1);
    }

    .variant-ref-content.isgrid {
        white-space: nowrap;
        overflow-x: hidden;
        max-width: 12.2rem;
    }
}

.loading-discount {
    transform: scale(.8);
    top: 6px;
    position: relative;
    right: -3px;
}

.shopping-cart-icon-parent {
    width: 50px;
    height: 50px;
    border: 2px solid var(--clr-blue);
    border-radius: 50%;
    padding: 9px 3px 7px 7px;
    transition: background-color .7s;
}

.shopping-cart-icon-parent:hover {
    background-color: var(--clr-blue);
}

.shopping-cart-icon-parent:hover .shopping-cart-icon {
    color: var(--clr-light);
}

.addtocart-parent {
    width: 100%;
    height: 50px;
    border: 2px solid var(--clr-purple);
    background-color: var(--clr-purple);
    border-radius: 7px;
    color: var(--clr-light);
    padding: 9px 3px 7px 7px;
    transition: var(--transition);
    font-weight: 600;
    font-size: 17px;
}

.addtocart-parent:hover {
    background-color: var(--clr-light-purple);
    color: var(--clr-purple);
}

.addtocart-parent:hover .shopping-cart-icon {
    color: var(--clr-light);
}


.shopping-cart-icon {
    color: var(--clr-blue);
    font-size: 40px;
    transition: color .7s;
}

.shopping-cart-add-to {
    z-index: 9999;
}

.variant-row-m1 {
    margin: 33px 0px 0 0 !important;
    left: 0;
}

.variant-row-m2 {
    margin: 0px 0px 4rem 0 !important;
    height: 3.9rem;
    bottom: 0;
    left: 0;
    position: fixed;
}


.variant-icon-content {
    width: 33px !important;
    height: 33px !important;
    border-radius: 50% !important;
    position: relative !important;
}

.notselected-border {
    border: var(--border-solid-1) !important;
}

.selected-border {
    border: 2px solid var(--clr-blue) !important;
}

.variant-icon {
    width: 100% !important;
    height: 100% !important;
    border-radius: 50% !important;
    overflow: hidden !important;
    position: absolute !important;
    padding: 1px !important;
}

.variant-icon-img {
    object-fit: contain !important;
    height: 100% !important;
    width: 100% !important;
    overflow: hidden !important;
    border-radius: 50% !important;
}